import request from '@/router/axios';
import {businessUrl} from "@/config/env";

//新增
export const addFile = (row) => {
    return request({
        url: businessUrl + "productCategoryType/insert",
        method: "post",
        data: {
            ...row
        }
    })
}

//删除
export const  delFile = (id) => {
    return request({
        url: businessUrl + "productCategoryType/delete",
        method: "get",
        params: {
            "id": id,
        }
    })
}
//修改
export const  editFile = (row) => {
    return request({
        url: businessUrl + "productCategoryType/update",
        method: "post",
        data: {
            ...row
        }
    })
}
//查询
export const  fileList = (row) => {
    return request({
        url: businessUrl + "productCategoryType/selectPage",
        method: "post",
        data: {
            ...row
        }
    })
}

//根据名字查询
export const  selectByName = (value,id) => {
    return request({
        url: businessUrl + "productCategoryType/selectByName",
        method: "post",
        data: {
            "id":id,
            "name":value
        }
    })
}

//根据code查询
export const  selectByCode = (value,id) => {
    return request({
        url: businessUrl + "productCategoryType/selectByCode",
        method: "post",
        data: {
            "id":id,
            "code":value
        }
    })
}


//根据code查询
export const  selectAllItem = () => {
    return request({
        url: businessUrl + "productPropertyItem/selectAll",
        method: "get",
        params: {
        }
    })
}
